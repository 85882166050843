import { Slice, type PageProps } from 'gatsby';
import { type FC } from 'react';
import { twJoin } from 'tailwind-merge';

import type { DefaultPageContext } from 'src/types/custom-types';

const Layout: FC<PageProps<{}, DefaultPageContext>> = ({
  location,
  pageContext: { language },
  children,
}) => {
  // NOTE:
  // gatsby-plugin-offlineを使用すると、
  // swインストール後、一旦fallbackページを経由する場合があり、
  // その際hydrationエラーが発生するため
  // fallbackページの場合はnullを返す
  // エラー内容
  // https://reactjs.org/docs/error-decoder.html/?invariant=418
  // https://reactjs.org/docs/error-decoder.html/?invariant=423
  if (location.pathname === '/offline-plugin-app-shell-fallback/') {
    return null;
  }
  return (
    <div>
      <Slice
        className={twJoin('fixed left-0 top-0 z-30 w-full')}
        alias="header"
      />
      <div
        className={twJoin(
          'mx-auto flex min-h-s-screen w-[80%] flex-col pb-4 md:w-max-content'
        )}
      >
        <main className={twJoin('flex-grow')}>{children}</main>
        <Slice
          className={twJoin('relative z-20')}
          alias="footer"
          language={language}
        />
      </div>
    </div>
  );
};
export default Layout;
